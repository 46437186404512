import React, { useEffect, useContext, useState } from "react";
import Header from "../../../Navs/Header";
import NavBarVerticalV2 from "../../navbarVerticalV2/navbarVerticalV2";
import * as IconlyPack from "react-iconly";
import FactureTable from "../../../Tables/Facture/FactureTable";
import { DataContext } from "../../../store/GlobalState";
import CRUDFactureModal from "../../../Modals/CRUDFactureModal";
import UseCreateFatureForm from "./UseCreateFatureForm";
import { getDataByID } from "../../../utils/fetchData";
import ShowFactureModal from "../../../Modals/ShowFactureModal";
import EditFactureModal from "../../../Modals/EditFactureModal";
import FacturePdfModal from "../../../Modals/FacturePdfModal";

var GestionFacture = () => {
  const { state, dispatch } = useContext(DataContext);
  const { auth, entreprises, prestataire, ipms } = state;

  const {
    register,
    formState,
    facture,
    setFacture,
    setValue,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit,
    reset,
    isSuccess,
  } = UseCreateFatureForm();

  const [beneficiaires, setbeneficiaires] = useState([]);
  const [type_prestations, setType_prestations] = useState([]);

  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      if (auth.user?.user_type === "prestataire") {
        getDataByID("facturebyprestataire", auth?.user?.id, auth?.token).then(
          (res) => {
            dispatch({
              type: "LIST_FACTURE_BY_PRESTATAIRE",
              payload: res.sort((a, b) => a?.id - b?.id).reverse(),
            });
          }
        );

        getDataByID(
          "notificationbyprestataire",
          auth?.user?.id,
          auth?.token
        ).then((res) => {
          // console.log("notif", res);
          dispatch({
            type: "LIST_NOTIFICATION",
            payload: res.sort((a, b) => a?.id - b?.id).reverse(),
          });
        });
      }
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.user) {
      if (prestataire?.assurance_maladie?.length) {
        prestataire.assurance_maladie.forEach(async (element) => {
          await getDataByID(
            "beneficiairebyassurance",
            element,
            auth?.token
          ).then((res) => {
            let datas = [...beneficiaires, ...res];
            setbeneficiaires(datas);
          });

          await getDataByID(
            "typeprestationbyassurance",
            element,
            auth?.token
          ).then((res) => {
            let types = [...type_prestations, ...res];
            setType_prestations(types);
          });
        });
      }
    }
    // eslint-disable-next-line
  }, [auth, prestataire?.assurance_maladie?.length]);

  //   console.log('types', type_prestations)
  useEffect(() => {
    if (formState.errors) {
      // console.log("formState=>", formState.errors)
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
    // eslint-disable-next-line
  }, [formState]);

  return (
    <div className="dashboard-item-container">
      <div className="container-fluid body-background-dashboard">
        <Header />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="nav-container">
            <NavBarVerticalV2 userType={`prestataire`} />
          </div>

          <div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 presty">
            <div className="page-body-container">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">Facturation des clients</h1>
                  </div>

                  <div
                    className="adding-item-container linkable"
                    data-toggle="modal"
                    data-target=".add-facture-modal"
                  >
                    <span className="icon-container">
                      <IconlyPack.Plus set="light" primaryColor="gray" />
                    </span>
                    <span className="adding-item-label">
                      Ajouter une facture
                    </span>
                  </div>
                </div>
                <div className="parent-table-container">
                  <FactureTable />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal add-facture-modal"
                  id="AddModalItem"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <CRUDFactureModal
                    register={register}
                    onSubmit={onSubmit}
                    formState={formState}
                    isSuccessfullySubmitted={isSuccessfullySubmitted}
                    setIsSuccessfullySubmitted={setIsSuccessfullySubmitted}
                    setValue={setValue}
                    ipms={ipms}
                    prestataire={auth?.user?.id}
                    reset={reset}
                    isSuccess={isSuccess}
                    isFormRequestError={isFormRequestError}
                  />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal show-facture-modal"
                  id="AddModalItem"
                  data-backdrop="static"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <ShowFactureModal />
                </div>

                <div
                  class="modal fade"
                  id="facturePdfModal"
                  tabIndex="-1"
                  data-backdrop="static"
                  aria-labelledby="facturePdfModalLabel"
                  aria-hidden="true"
                >
                  <FacturePdfModal />
                </div>

                {/* Modal */}
                <div
                  className="modal fade easypm-modal edit-facture-modal"
                  id="AddModalItem"
                  data-backdrop="static"
                  tabIndex="-1"
                  aria-labelledby="AddModalItemLabel"
                  aria-hidden="true"
                >
                  <EditFactureModal
                    beneficiaires={beneficiaires}
                    prestation_types={type_prestations}
                    entreprises={entreprises}
                    prestataires={auth?.user?.id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionFacture;
