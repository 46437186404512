/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useContext, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ResetPasswordModal from "./ResetPasswordModal";
import EmptyHeader from "./EmptyHeader";
import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import { userLogin } from "../Services/Endpoints";
import { DataContext } from "../store/GlobalState";
import { useHistory } from "react-router-dom";

export default function Connexion() {
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors, isSubmitted },
  // } = useForm()
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);

  const history = useHistory();

  const { state, dispatch } = useContext(DataContext);
  const { auth } = state;

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .email("L'adresse email n'est pas valide")
          .required("L'email est requis"),
        password: yup.string().required("Le mot de passe est requis"),
      }),
    []
  );

  const { register, handleSubmit, formState, clearErrors } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: auth?.user,
  });

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState]);

  useEffect(() => {
    if (
      (auth.token || window.sessionStorage.getItem("authToken")) &&
      (window.sessionStorage.getItem("userType") === "assurance_sante" ||
        window.sessionStorage.getItem("userType") === "ipm")
    ) {
      history.push("/ipm/dashboard");
    }

    if (
      (auth.token || window.sessionStorage.getItem("authToken")) &&
      window.sessionStorage.getItem("userType") === "entreprise"
    ) {
      history.push("/entreprise/dashboard");
    }

    if (
      (auth.token || window.sessionStorage.getItem("authToken")) &&
      window.sessionStorage.getItem("userType") === "prestataire"
    ) {
      history.push("/prestataire/dashboard");
    }

    if (
      (auth.token || window.sessionStorage.getItem("authToken")) &&
      window.sessionStorage.getItem("userType") === "assureur"
    ) {
      history.push("/assurance/dashboard");
    }

    if (
      (auth.token || window.sessionStorage.getItem("authToken")) &&
      window.sessionStorage.getItem("userType") === "beneficiaire"
    ) {
      history.push("/dashboard-beneficiaire");
    }

    if (
      (auth.token || window.sessionStorage.getItem("authToken")) &&
      window.sessionStorage.getItem("userType") === "admin"
    ) {
      history.push("/admin/dashboard");
    }
  }, []);

  const onSubmit = async (data) => {
    setIsLoginInProgress(true);
    await userLogin(data).then(async () => {
      let token = await window.sessionStorage.getItem("authToken");
      let user = await JSON.parse(window.sessionStorage.getItem("user"));
      //sessionStorage.setItem('userType', user?.id)
      var userType = "";

      var isSuperAdmin = false;
      if (user?.is_superuser) {
        isSuperAdmin = await user?.is_superuser;
        await window.sessionStorage.setItem("isSuperAdmin", isSuperAdmin);
      }
      userType = await window.sessionStorage.getItem("userType");

      await dispatch({
        type: "AUTH",
        payload: {
          token: token,
          user: user,
        },
      });

      setIsLoginInProgress(false);
      if (
        token &&
        (userType === "assurance_sante" ||
          userType === "ipm" ||
          userType === "admin_ipm") &&
        !user?.is_superuser
      ) {
        if (user?.first_connexion) {
          window.location = "/ipm/change-password";
        } else {
          window.location = "/ipm/dashboard";
        }
        window.sessionStorage.removeItem("user");
        // console.log(window.sessionStorage.getItem('authToken'))
      }

      if (token && userType === "entreprise" && !user?.is_superuser) {
        if (user?.first_connexion) {
          window.location = "/entreprise/change-password";
        } else {
          window.location = "/entreprise/dashboard";
        }
        // window.location = '/entreprise/dashboard'
        window.sessionStorage.removeItem("user");
        // console.log(auth.user.user_type)
      }

      if (token && userType === "prestataire" && !user?.is_superuser) {
        if (user?.first_connexion) {
          window.location = "/prestataire/change-password";
        } else {
          window.location = "/prestataire/dashboard";
        }

        window.sessionStorage.removeItem("user");
        // console.log(auth?.user?.user_type)
      }

      if (token && (user?.is_superuser || userType === "super_ipm")) {
        window.location = "/admin/gestion-des-ipm";
        window.sessionStorage.removeItem("user");
      }
      if (token && userType === "assureur" && !user?.is_superuser) {
        if (user?.first_connexion) {
          window.location = "/assurance/change-password";
        } else {
          window.location = "/assurance/dashboard";
        }

        window.sessionStorage.removeItem("user");
      }

      if (token && userType === "beneficiaire" && !user?.is_superuser) {
        if (user?.first_connexion) {
          window.location = "/beneficiaire/change-password";
        } else {
          window.location = "/dashboard-beneficiaire";
        }

        window.sessionStorage.removeItem("user");
      }
    }).catch((e) => {
      setIsLoginInProgress(false)
      toast.error(e?.response?.data?.message ||
        "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard."
      );
    });
  };
  //console.log('aurh', auth?.user)
  return (
    <>
      <EmptyHeader />
      <div className="container login-page-container">
        <div className="row login-page-row">
          <div className="col-md-6 left-side-col"></div>

          <div className="col-md-6 right-side-col">
            <div className="login-form-container">
              <div className="login-form-introduction">
                <h1 className="login-title-label">Connectez-vous</h1>
              </div>
              <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="user" />
                    </span>
                  </div>
                  <input
                    name="email"
                    id="email"
                    type="text"
                    className="form-control input-with-icon"
                    {...register("email")}
                    placeholder="Adresse mail"
                  />
                  {formState.errors?.email && (
                    <div className="alert alert-danger easypm-alert-danger">
                      {" "}
                      {formState.errors?.email?.message}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text span-input-icon"
                      id="basic-addon1"
                    >
                      <FeatherIcon className="icon-input" icon="lock" />
                    </span>
                  </div>
                  <input
                    name="password"
                    id="password"
                    {...register("password")}
                    type="password"
                    className="form-control input-with-icon"
                    placeholder="Mot de passe"
                  />
                  {formState.errors?.password && (
                    <div className="alert alert-danger easypm-alert-danger">
                      {" "}
                      {formState.errors?.password?.message}{" "}
                    </div>
                  )}
                </div>

                <div className="dis-flex justify-content-end m-b-40">
                  <button
                    onClick={(e) => e.preventDefault()}
                    type="button"
                    className="mdpOublie"
                    data-toggle="modal"
                    data-target=".open-reset-password-modal"
                  >
                    Mot de passe oublié ?
                  </button>
                </div>

                <div className="row submit-btn-row">
                  <div className="col-md-8 offset-md-4 submit-btn-col">
                    {isLoginInProgress === false ? (
                      <button type="submit" className="btn-connexion">
                        Connexion
                      </button>
                    ) : (
                      <button className="btn-connexion in-progress-btn">
                        Connexion en cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <ResetPasswordModal />
          </div>
        </div>
      </div>
    </>
  );
}
