/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../store/GlobalState";
import jsPDF from "jspdf";
import html2canvas from "jspdf-html2canvas";
import html2PDF from "jspdf-html2canvas";
import $ from "jquery";
import IMGEPM from "../../images/others/easypm.png";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import moment from "moment";
import { formatMoney, formatMontant } from "../../utils/utils";
import { getDataByID } from "../utils/fetchData";
import baseUrl from "../../Http/backend-base-url";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const FacturePdfDataModal = () => {
  const { state, dispatch } = useContext(DataContext);

  const { facture, auth, beneficiairesByEntreprise } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [partIpmTotal, setPartIpmTotal] = useState(0);
  const [partBenefTotal, setPartBenefTotal] = useState(0);
  const [benefTotal, setBenefTotal] = useState(0);
  const [total, setTotal] = useState(0);
  /* Chart code */

  // Create chart instance
  let chart = null;

  // Create chart instance
  let chart1 = null;

  useEffect(() => {
    if (facture?.id) {
      chart = am4core.create("chartdiv", am4charts.PieChart);
      chart1 = am4core.create("chartdiv1", am4charts.PieChart);
      // Add data
      chart.data = [
        {
          part: "Part IPM",
          pourcentage: facture?.charge_ipm,
          color: am4core.color("#f2cb67"),
        },
        {
          part: "Part Participant",
          pourcentage: facture?.charge_beneficiaire,
          color: am4core.color("#fd8a65"),
        },
      ];

      chart1.data = [
        {
          part: "Cotisations",
          pourcentage: parseInt(
            facture?.cotisations ? facture?.cotisations : 0
          ),
          color: am4core.color("#047eb1"),
        },
        {
          part: "Frais médicaux",
          pourcentage: facture?.frais_medicaux,
          color: am4core.color("rgb(75, 192, 192)"),
        },
      ];

      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.slices.template.propertyFields.fill = "color";
      pieSeries.dataFields.value = "pourcentage";
      pieSeries.dataFields.category = "part";
      pieSeries.innerRadius = am4core.percent(50);
      pieSeries.ticks.template.disabled = true;
      pieSeries.labels.template.disabled = true;

      let rgm = new am4core.RadialGradientModifier();
      rgm.brightnesses.push(0, 0, 0, 0, 0);
      pieSeries.slices.template.fillModifier = rgm;
      pieSeries.slices.template.strokeModifier = rgm;
      pieSeries.slices.template.strokeOpacity = 0.4;
      pieSeries.slices.template.strokeWidth = 0;

      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";

      // var gradient = new am4core.RadialGradient();

      // Add and configure Series
      let pieSeries1 = chart1.series.push(new am4charts.PieSeries());
      pieSeries1.slices.template.propertyFields.fill = "color";
      pieSeries1.dataFields.value = "pourcentage";
      pieSeries1.dataFields.category = "part";
      pieSeries1.innerRadius = am4core.percent(50);
      pieSeries1.ticks.template.disabled = true;
      pieSeries1.labels.template.disabled = true;

      let rgm1 = new am4core.RadialGradientModifier();
      rgm1.brightnesses.push(0, 0, 0, 0, 0);
      pieSeries1.slices.template.fillModifier = rgm1;
      pieSeries1.slices.template.strokeModifier = rgm1;
      pieSeries1.slices.template.strokeOpacity = 0.4;
      pieSeries1.slices.template.strokeWidth = 0;

      chart1.legend = new am4charts.Legend();
      chart1.legend.position = "right";
      let total_mont = 0;
      let total_ipm = 0;
      let total_benef = 0;
      if (facture.prestations?.length) {
        facture.prestations.forEach((element) => {
          total_mont += parseInt(element.montant);
          total_ipm += parseInt(element.montant_rembourse);
          total_benef += parseInt(element.montant_retenu);
        });
      }
      
      setPartBenefTotal(total_benef);
      setPartIpmTotal(total_ipm);

      setTotal(
        total_mont +
          parseInt(
            facture?.ipm?.montant_cotisation
              ? facture?.ipm?.montant_cotisation
              : 0
          )
      );
    } else {
      setPartBenefTotal(0);
      setPartIpmTotal(0);
      setTotal(0);
      chart = null;
      chart1 = null;
      // return () => {
      //   chart.dispose();
      //   chart1.dispose();
      // };
    }
  }, [facture]);

  //   const  generatePDF = () => {

  //         var doc = new jsPDF('p','pt','a4');
  //        doc.html(document.querySelector("#content"), { height: 1800, width: window.innerWidth * 2, scale: 1 }).then(canvas => {

  //         var dataURL = canvas.toDataURL();
  //         // var pdf = new jsPDF('p','pt','a4');
  //         doc.addImage(dataURL, 'JPEG', 20, 20, 170, 120); //addImage(image, format, x-coordinate, y-coordinate, width, height)
  //         //    callback: function(pdf){

  //                doc.save("facture.pdf");
  //         //    }
  //        });
  //       } ;
  // $("#generatePDF").click(function()
  const generatePDF = () => {
    setIsLoading(true);
    const input = document.getElementById("content");
    html2PDF(input, {
      jsPDF: {
        unit: "px",
        format: "a4",
      },
      html2canvas: {
        imageTimeout: 15000,
        logging: true,
        useCORS: false,
      },
      imageType: "image/png",
      imageQuality: 1,
      margin: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5,
      },
      output: `facture-client-${moment(new Date()).format("DD-MM-YYYY")}.pdf`,
      init: function () {},
      success: function (pdf) {
        pdf.save(this.output);
        window.$("#facturePdfModal").modal("hide");
        window.$("#FacturePdfDataModal").modal("hide");
        setIsLoading(false);
      },
    });
  };

  const handleReset = () => {
    setIsLoading(false);
    dispatch({ type: "EDIT_FACTURE", payload: {} });
  };
  return (
    <div class="modal-dialog modal-xl" id="modal-facture">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="facturePdfModalLabel">
            Facture pdf
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body px-4">
          <div className="row d-flex justify-content-center pb-4">
            <div className="col-md-6">
              {!isLoading && (
                <button
                  className="btn pdf-created-link"
                  //  id="generatePDF"
                  onClick={() => generatePDF()}
                  type="button"
                >
                  Télécharger la facture
                </button>
              )}
              {isLoading && (
                <button
                  className="btn pdf-created-link"
                  //  id="generatePDF"
                  //   onClick={() => generatePDF()}
                  type="button"
                >
                  Téléchargement en cours &nbsp;
                  <i className="fas fa-spin fa-spinner"></i>
                </button>
              )}
            </div>
          </div>
          <div id="content">
            <div className="row d-flex align-items-center">
              <div className="col-md-6">
                <div className>
                  <img src={IMGEPM} className="img-facture" />
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <div className="blocright d-flex">
                  <div className="bgblue">
                    <p className="textfacturebloc">Numéro de facture</p>
                    <p className="textlibellebloc">{facture?.id}</p>
                  </div>
                  <div className="bggreen">
                    <p className="textfacturebloc">Référence client</p>
                    <p className="textlibellebloc">
                      {facture?.entreprise
                        ? facture?.entreprise?.id
                        : facture?.ipm?.id}
                    </p>
                  </div>
                  <div className="bgblue">
                    <p className="textfacturebloc">Facture du</p>
                    <p className="textlibellebloc">
                      {moment(facture?.created_at).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row py-3">
              {/* {facture?.ipm && facture?.ipm?.logo && (
                <div className="col-md-12">
                  <img src={baseUrl + facture?.ipm?.logo} width="100" />
                </div>
              )} */}

              <div className="col-md-6">
                <p className="p-text text-dark">
                  {facture?.prestataire
                    ? facture?.prestataire?.prenom
                      ? facture?.prestataire?.prenom +
                        " " +
                        facture?.prestataire?.nom
                      : facture?.prestataire?.raison_sociale
                    : facture?.ipm?.raison_sociale}
                </p>

                <p className="p-text">
                  {" "}
                  {facture?.prestataire
                    ? facture?.prestataire?.adresse
                    : facture?.ipm?.adresse}
                </p>
                <p className="p-text">
                  {facture?.prestataire
                    ? facture?.prestataire?.telephone
                    : facture?.ipm?.telephone}
                </p>
                <p className="p-text">
                  {facture?.prestataire
                    ? facture?.prestataire?.email
                    : facture?.ipm?.email}
                </p>
              </div>

              <div className="col-md-6 d-flex justify-content-center">
                <ul className="">
                  <li className="list">
                    Nom du client :{" "}
                    <span className="spanli">
                      {facture?.entreprise
                        ? facture?.entreprise?.raison_sociale
                        : facture?.ipm?.raison_sociale}
                    </span>
                  </li>
                  <li className="list">
                    Adresse du client :{" "}
                    <span className="spanli">
                      {facture?.entreprise
                        ? facture?.entreprise?.adresse
                        : facture?.ipm?.adresse}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bloctrois">
              <div className="textbloctrois py-2 pl-2">
                Règlements des sommes dues:{" "}
                {moment(facture?.date_debut).format("DD/MM/YYYY")} au{" "}
                {moment(facture?.date_fin).format("DD/MM/YYYY")}1
              </div>
            </div>
            <div className="py-3">
              <p className="text"> Mesdames, Messieurs</p>
              <p className="text">
                Veuillez trouver ci-dessous le montant des sommes à régler
              </p>
            </div>
            <div className="pt-3">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Quantité </th>
                    <th scope="col">Prix unitaire</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">COTISATIONS</th>
                    <td> {facture?.participants} </td>
                    <td>
                      {formatMoney(parseInt(facture?.ipm?.montant_cotisation))}{" "}
                    </td>
                    <td>{formatMoney(parseInt(facture?.cotisations))} </td>
                  </tr>
                  <tr>
                    <th scope="row">FRAIS MÉDICAUX</th>
                    <td></td>
                    <td>
                      {facture?.frais_medicaux
                        ? formatMoney(facture?.frais_medicaux)
                        : 0}{" "}
                    </td>
                    <td>
                      {facture?.frais_medicaux
                        ? formatMoney(facture?.frais_medicaux)
                        : 0}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <ul class="list-group">
                {/* <li class="list-group-item textentete d-flex justify-content-between align-items-center">
                  A<span className="entete">FCFA</span>
                </li> */}
                <li class="list-group-item listgray d-flex justify-content-between align-items-center">
                  Règlement effectué
                  <span className="spanlist">{formatMoney(0)}</span>
                </li>
                <li class="list-group-item listgray d-flex justify-content-between align-items-center">
                  Restant dû
                  <span className="spanlist">
                    {formatMoney(facture?.total)}
                  </span>
                </li>
                <li class="list-group-item listred d-flex justify-content-between align-items-center">
                  Total Facture
                  <span className="listtotal">
                    {formatMoney(facture?.total)}
                  </span>
                </li>
              </ul>
            </div>
            <div className="row pt-3 pb-5 mt-5" id="canvas">
              <div className="col-md-6">
                <p className="text-cout">Répartition des coûts </p>
                {facture?.id && <div id="chartdiv"></div>}
              </div>
              <div className="col-md-6">
                <p className="text-cout">Répartition des coûts </p>
                {facture?.id && <div id="chartdiv1"></div>}
              </div>
            </div>
            <div className="my-5 py-5"></div>
            <div>
              <div className="bloctrois mt-5">
                <div className="textbloctrois py-2 pl-2">
                  Etat Récapitulatif des Prestations
                </div>
              </div>
              <div className="pt-3">
                <table class="table" id="page-2">
                  <thead>
                    {facture?.prestations?.length ? (
                      <tr>
                        <th scope="col">N°.Participant</th>
                        <th scope="col">Prénom et Nom </th>
                        <th scope="col">Désignation</th>
                        <th scope="col">Date</th>
                        <th scope="col" className="text-right">
                          Part IPM (F CFA){" "}
                        </th>
                        <th scope="col" className="text-right">
                          Part Participant (F CFA)
                        </th>
                        <th scope="col" className="text-right">
                          Total (F CFA)
                        </th>
                      </tr>
                    ) : null}
                  </thead>
                  <tbody>
                    {facture?.prestations?.length ? (
                      facture?.prestations
                        .sort((a, b) => new Date(a?.id) - new Date(b?.id))
                        .map((prestation) => (
                          <tr key={prestation?.id}>
                            <td className="fact-cls">
                              {prestation?.beneficiaire?.id}
                            </td>
                            <td className="fact-cls">
                              <span className="name">
                                {" "}
                                {prestation?.beneficiaire?.prenom +
                                  " " +
                                  prestation?.beneficiaire?.nom}{" "}
                              </span>
                            </td>
                            <td className="fact-cls">
                              Famille de soins&nbsp; (
                              {prestation?.familledesoin?.nom})
                              &nbsp;/&nbsp;Type de soins&nbsp; (
                              {prestation?.typedesoin?.nom})&nbsp;/&nbsp;
                              {prestation?.designation}
                            </td>
                            <td className="fact-cls">
                              {moment(prestation?.date).format("DD/MM/YYYY")}
                            </td>

                            <td className="fact-cls text-right">
                              {formatMontant(
                                parseInt(prestation?.montant_rembourse) *
                                  parseInt(prestation?.quantite)
                              )}{" "}
                            </td>
                            <td className="fact-cls text-right">
                              {formatMontant(
                                parseInt(prestation?.montant_retenu) *
                                  parseInt(prestation?.quantite)
                              )}{" "}
                            </td>
                            <td className="fact-cls text-right">
                              {formatMontant(
                                parseInt(prestation?.montant) *
                                  parseInt(prestation?.quantite)
                              )}
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr
                        style={{
                          textAlign: "center",
                          backgroundColor: "transparent",
                          fontSize: "20px",
                        }}
                      >
                        <td className="totalText col-td" colSpan="6">
                          <span>Aucune prestation disponible</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <div className="bloctrois mt-5">
                <div className="textbloctrois py-2 pl-2">
                  Etat Récapitulatif des cotisations
                </div>
              </div>
              <div className="pt-3">
                <table class="table" id="page-2">
                  <thead>
                    {(beneficiairesByEntreprise?.length ||
                      facture?.beneficiaires?.length) &&
                    facture?.cotisations &&
                    facture?.cotisations > 0 ? (
                      <tr>
                        <th scope="col" className="text-center">
                          N°.Participant{" "}
                        </th>
                        <th scope="col">Prénom et Nom </th>
                        <th scope="col">Employeur</th>
                        <th scope="col" className="text-right">
                          Part Participant (F CFA){" "}
                        </th>
                        <th scope="col" className="text-right">
                          Part employeur (F CFA){" "}
                        </th>
                        <th scope="col" className="text-right">
                          Total (F CFA){" "}
                        </th>
                      </tr>
                    ) : null}
                  </thead>
                  <tbody>
                    {facture?.beneficiaires?.length &&
                    facture?.cotisations &&
                    facture?.cotisations > 0 ? (
                      facture?.beneficiaires
                        .sort((a, b) => new Date(a?.id) - new Date(b?.id))
                        .map((beneficiaire) => (
                          <tr key={beneficiaire?.id}>
                            <td>{beneficiaire?.id}</td>
                            <td className="fact-cls">
                              {beneficiaire?.prenom + " " + beneficiaire?.nom}
                            </td>
                            <td className="fact-cls">
                              {beneficiaire?.employeur?.raison_sociale}
                            </td>
                            <td className="fact-cls text-right">
                              {formatMontant(
                                parseInt(facture?.ipm?.montant_cotisation) / 2
                              )}
                            </td>
                            <td className="fact-cls text-right">
                              {formatMontant(
                                parseInt(facture?.ipm?.montant_cotisation) / 2
                              )}
                            </td>
                            <td className="fact-cls text-right">
                              {formatMontant(
                                parseInt(facture?.ipm?.montant_cotisation)
                              )}{" "}
                            </td>
                          </tr>
                        ))
                    ) : beneficiairesByEntreprise?.length &&
                      facture?.cotisations &&
                      facture?.cotisations > 0 ? (
                      beneficiairesByEntreprise
                        .sort((a, b) => new Date(a?.id) - new Date(b?.id))
                        .map((beneficiaire) => (
                          <tr key={beneficiaire?.id}>
                            <td>{beneficiaire?.id}</td>
                            <td className="fact-cls">
                              {beneficiaire?.prenom + " " + beneficiaire?.nom}
                            </td>
                            <td className="fact-cls">
                              {beneficiaire?.employeur?.raison_sociale}
                            </td>
                            <td className="fact-cls text-right">
                              {formatMontant(
                                parseInt(facture?.ipm?.montant_cotisation) / 2
                              )}
                            </td>
                            <td className="fact-cls text-right">
                              {formatMontant(
                                parseInt(facture?.ipm?.montant_cotisation) / 2
                              )}
                            </td>
                            <td className="fact-cls text-right">
                              {formatMontant(
                                parseInt(facture?.ipm?.montant_cotisation)
                              )}{" "}
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr
                        style={{
                          textAlign: "center",
                          backgroundColor: "transparent",
                          fontSize: "20px",
                        }}
                      >
                        <td className="totalText col-td" colSpan="6">
                          <span>Aucune cotisation disponible</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default FacturePdfDataModal;
