import React, { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { DataContext } from "../../../store/GlobalState";
import Facture from "../../../Services/Facture";
import { getDataByID } from "../../../utils/fetchData";
import moment from "moment";

const UseCreateFacturation = () => {
  const { state, dispatch } = useContext(DataContext);
  let { auth } = state;

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [facture, setFacture] = useState();
  const [isFormRequestError, setIsFormRequestError] = useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        entreprise: yup.string().required("L'adhérent est requis."),
        date_debut: yup
          .string()
          .required("La date de début est requise")
          .default(moment(new Date()).format("YYYY-MM-DD")),
        date_fin: yup
          .string()
          .required("La date de fin est requise")
          .default(moment(new Date()).format("YYYY-MM-DD")),
        // type_prestation: yup.string().required('Le type de prestation est requis'),
        prestataire: yup.string(),
        // montant: yup.string().required('Le Montant est requis'),
      }),
    []
  );

  const { register, handleSubmit, setValue, formState, clearErrors, reset } =
    useForm({
      resolver: yupResolver(validationSchema),
    });

  React.useEffect(() => {
    if (auth?.user?.user_type === "pretataire") {
      register("prestataire");
      setValue("prestataire", auth?.user?.id);
    } else {
      if (auth?.user?.user_type === "admin_ipm") {
        register("ipm");
        setValue("ipm", auth?.user?.parent);
      } else {
        register("ipm");
        setValue("ipm", auth?.user?.id);
      }
    }
  }, [setValue, auth, register]);

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    console.log("data", formValues);
    if (auth?.user?.user_type !== "pretataire") {
      if (auth?.user?.user_type === "admin_ipm") {
        formValues.ipm = auth?.user?.parent;
      } else {
        formValues.ipm = auth?.user?.id;
      }
    }

    var prestationRequest = Facture.createFacture(formValues, requestConfig);
    await prestationRequest
      .then((response) => {
        e.target.reset();
        console.log("response", response);
        if (auth?.user?.user_type === "admin_ipm") {
          getDataByID("facturebyipm", auth?.user?.parent, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_FACTURE_BY_IPM",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        } else {
          getDataByID("facturebyipm", auth?.user?.id, auth?.token).then(
            (res) => {
              dispatch({
                type: "LIST_FACTURE_BY_IPM",
                payload: res.sort((a, b) => a?.id - b?.id).reverse(),
              });
            }
          );
        }

        if (response?.id) {
          dispatch({ type: "EDIT_FACTURE", payload: response });
          dispatch({
            type: "LIST_PRESTATION_BY_FACTURE",
            payload: response?.prestations?.sort((a, b) => b?.id - a?.id),
          });
          
          toast.success("Facture générée avec succès.");
        } 
        // else {
        //   toast.warning(response?.message);
        //   window.$(".genere-facture-modal").modal("hide");
        //   setIsFormRequestError(true);
        //   setTimeout(() => {
        //     setIsFormRequestError(false);
        //   }, 2000);
        // }
        setIsSuccessfullySubmitted(false);
        // window.$('.genere-facture-modal').modal('hide')
        reset();
        setIsSuccess(true);

        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setIsSuccessfullySubmitted(false);
        // setIsFormRequestError(true);
        // toast.error("Oupss! Une erreur est survenue lors de l'ajout."
        if (error?.response?.data?.message) {
          toast.error(error?.response.data.message);
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (error?.response?.status === 400) {
          toast.error(
            "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
          );
        }
      });
  };

  return {
    register,
    setValue,
    formState,
    facture,
    setFacture,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    reset,
    onSubmit: handleSubmit(onSubmitCreateForm),
    isSuccess,
  };
};

export default UseCreateFacturation;
